import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg'

const Footer = props => (
  <footer id="footer">
    <section className="center">
      <h2>Contact Us</h2>
      <ul className="icons">
        <li>
          <a href="mailto:info@memz.co" className="icon fa-envelope alt">
            <span className="label">Email</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/www.memz.co"
            target="_blank"
            className="icon fa-facebook alt"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">&copy; 2021 Memz Pty Ltd</p>
  </footer>
)

export default Footer
